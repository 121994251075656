/* Add this CSS to your stylesheet */
.StripeElement {
  box-sizing: border-box;
  height: 40px;
  padding: 10px 12px;
  border: 1px solid white;
  border-radius: 4px;
  background-color: black;
  color: white;
  font-family: 'Helvetica Neue', Helvetica, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 40px;
  width: 300px;
}

.StripeElement::placeholder {
  color: white;
}

.StripeElement--focus {
  box-shadow: 0 1px 3px 0 #cfd7df;
}

.StripeElement--invalid {
  border-color: #fa755a;
}

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}

/* Button styling */
button {
  background-color: black;
  border:1px solid white;
  color: white;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 4px;
}

button:disabled {
  background-color: #c3e6cb;
}
